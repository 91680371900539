import type { IContentItem, IContentItemsContainer } from '@kontent-ai/delivery-sdk'
import { defineStore } from 'pinia'
import { contentTypes, type ContactBlockModel, type HomepageModel } from '~/models'
interface PageState {
  page: IContentItem | null
  linkedItems: IContentItemsContainer
}

export const usePageStore = defineStore('page', {
  state: (): PageState => ({
    page: null,
    linkedItems: {} as IContentItemsContainer
  }),
  actions: {
    async fetchData(itemId: string) {
      const response = await $fetch('/api/page/' + itemId)
      if (response) {
        //@ts-ignore
        this.page = response.data.item as IContentItem
        //@ts-ignore
        this.linkedItems = response.data.linkedItems as IContentItemsContainer
        //   this.dataLoaded = true
      }
    },
    async fetchHome() {
      const response = await $fetch('/api/home')
      if (response) {
        //@ts-ignore
        this.page = response.data.item as HomepageModel
        //@ts-ignore
        this.linkedItems = response.data.linkedItems as IContentItemsContainer
      }
    },
    async fetchContactUsBlock() {
      const response = await $fetch('/api/contact-us')
      if (response) {
        //@ts-ignore
        return response.data.item as ContactBlockModel
      }
      return null
    }
  },
  getters: {
    getLinkedItem:
      (state) =>
      <T>(codename: string) => {
        return state.linkedItems?.[codename] as T
      },
    pageTitle: (state) => {
      if (!state.page) return ''

      return state.page.elements.title.value
    }
  }
})
