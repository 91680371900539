<template>
    <section class="contact-block" v-bind:[attributes.componentId]="type.id">
        <div class="text-container">
            <div class="container" :class="paddingOptions(selectedPaddingOptions)">
                <div class="row">
                    <div class="col-lg-7 col-md-6">
                        <h2 class="title" v-bind:[attributes.elementCodename]="type.elements.title.codename">{{ title }}
                        </h2>
                        <div class="content" v-bind:[attributes.elementCodename]="type.elements.content.codename">
                            <RichText :value="content" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="image-container">
            <div v-if="imageUrl" class="desktop">
                <NuxtImg :src="imageUrl" v-bind:[attributes.elementCodename]="type.elements.image.codename" />
            </div>
            <div v-if="mobileImageUrl" class="mobile">
                <NuxtImg :src="mobileImageUrl" v-bind:[attributes.elementCodename]="type.elements.mobile_image.codename" />
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">

import { contentTypes, type ContactBlockModel } from '~/models';

const props = defineProps<{ model: ContactBlockModel }>();
const title = ref('');
const content = ref('');
const imageUrl = ref('');
const mobileImageUrl = ref('');

title.value = props.model.elements.title.value;
content.value = props.model.elements.content.value;
imageUrl.value = props.model.elements.image.value[0]?.url;
mobileImageUrl.value = props.model.elements.mobileImage.value[0]?.url;

const attributes = GetKontentAttributes();
const type = contentTypes._contact_block;

const selectedPaddingOptions = ref<string[]>([]);
selectedPaddingOptions.value = props.model.elements.paddingModificationsPaddingModifications.value.map((item) => item.codename);

</script>

<style lang="scss" scoped></style>