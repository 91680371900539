<template>
    <section class="cohort-list">
        <div class="container">
            <div class="row">
                <div class="col-lg">
                    <h2 class="title">
                        {{ title }}
                    </h2>
                </div>
            </div>
            <div class="row">
                <div v-for="item in items" :key="item.system.id" class="col-lg-4">

                    <NuxtLink :to="siteStore.getUrlByItemId(item.system.id)" class="card-link">
                        <div class="card">
                            <div class="card-logo">
                                <img v-if="item.elements.logo.value.length > 0"
                                    :src="item.elements.logo.value[0]?.url" />
                            </div>
                            <div class="card-info">
                                <h4 class="title-line-1">
                                    {{ item.elements.cardTitleLine1.value }}
                                </h4>
                                <div v-if="item.elements.cardTitleLine2.value.length > 0" class="title-line-2">
                                    {{ item.elements.cardTitleLine2.value }}</div>
                                <div class="focus">
                                    <label>Focus</label>
                                    <div class="value">{{ item.elements.researchFocus.value }}</div>
                                </div>
                                <div class="type">
                                    <label>Type</label>
                                    <div class="value">{{ item.elements.studyType.value }}</div>
                                </div>
                            </div>
                        </div>
                    </NuxtLink>
                </div>

            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import type { CohortItem2Model, CohortListModel } from '~/models';
const pageStore = usePageStore();
const siteStore = useSiteStore();
const props = defineProps<{ model: CohortListModel }>();
const items = ref<CohortItem2Model[]>([]);
const title = ref('');
items.value = [...props.model.elements.cohorts.value.map(codename => pageStore.getLinkedItem<CohortItem2Model>(codename))];
title.value = props.model.elements.title.value;
</script>