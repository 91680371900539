<template>
    <div class="horizontal-rule" v-bind:[attributes.componentId]="type.id" >
        <div class="container">
            <hr />
        </div>
    </div>
</template>

<script setup lang="ts">

import { contentTypes, type HorizontalRuleModel } from '~/models';

const props = defineProps<{ model: HorizontalRuleModel }>();

const attributes = GetKontentAttributes();
const type = contentTypes._horizontal_rule;
</script>

<style lang="scss" scoped>
</style>