import type { KontentAttributes } from "~/types/KontentAttributes";




export function GetKontentAttributes():KontentAttributes{
    const config = useRuntimeConfig();

    if (config.public.environment === 'staging'){

        return {
            projectId: 'data-kontent-project-id',
            languageCodename: 'data-kontent-language-codename',
            itemId: 'data-kontent-item-id',
            componentId: 'data-kontent-component-id',
            elementCodename: 'data-kontent-element-codename',
            addButton: 'data-kontent-add-button',
            addButtonInsertPosition: 'data-kontent-add-button-insert-position',
            addButtonRenderPosition: 'data-kontent-add-button-render-position',
            disableFeatures: 'data-kontent-disable-features'

        } as KontentAttributes
    }
    

    return {} as KontentAttributes;

}