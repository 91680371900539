<template>
    <table class="table">
        <thead>
            <tr>
                <th v-for="num in value.numColumns" class="table_header"></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in value.rows">
                <td v-for="cell in item.cells">
                    <template v-for="textItem in cell.content">
                        <PortableText :value="textItem" :components="components" />
                    </template>
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script lang="ts" setup>
import type { PortableTextComponentProps } from '@portabletext/vue';
import { PortableText } from "@portabletext/vue";
import PtComponent from "~/components/PortableText/PtComponent.vue"
import PtImage from "~/components/PortableText/PtImage.vue"
import PtTable from "~/components/PortableText/PtTable.vue"

const props = defineProps<
    PortableTextComponentProps<any>
>();

const value = props.value
const components = {
    types: {
        component: PtComponent,
        image: PtImage,
        table: PtTable,
    },
};
</script>