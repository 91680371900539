<template>
    <component v-if="activeComponent && !templateClass" :is="activeComponent" :model="model" :options="options" />
</template>

<script lang="ts" setup>

import {
    BannerBlock, ContactBlock, SplitImageBlock, LogoCollection, HorizontalRule,
    ImageBlock, CardList, CallToAction, SplitContentImageBlock, SplitContentBasicContentBlock,
    SplitContentBlock, BasicContentBlock, AccordionList, AccordionItem, AnchorTag, CohortList
} from '#components';
import type { IContentItem, IContentItemElements, IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import type { PortableTextComponentProps } from '@portabletext/vue';
import { contentTypes, type BannerModel, type ContactBlockModel, type BasicContentBlockModel, type SplitImageBlockModel, type CohortCollectionModel, type HorizontalRuleModel, type ImageBlockModel, type CardListModel, type CallToActionModel, type ImageBlockSplitContentModel, type SplitContentBlockModel, type AccordionListModel, type AccordionItemModel, type AnchorTagModel, type CohortListModel } from '~/models';

//#region Get Options Obj by type.

const optionsTypeMap = new Map<any, object>([]);


//#endregion

//#region  Content Type - GetComponent Map
const contentTypeMap = new Map<string, Function>([
    [contentTypes._banner.codename, (item: IContentItem<IContentItemElements>) => _getComponent<BannerModel>(item, BannerBlock)],
    [contentTypes._contact_block.codename, (item: IContentItem<IContentItemElements>) => _getComponent<ContactBlockModel>(item, ContactBlock)],
    [contentTypes._split_image_block.codename, (item: IContentItem<IContentItemElements>) => _getComponent<SplitImageBlockModel>(item, SplitImageBlock)],
    [contentTypes._cohort_collection.codename, (item: IContentItem<IContentItemElements>) => _getComponent<CohortCollectionModel>(item, LogoCollection)],
    [contentTypes._horizontal_rule.codename, (item: IContentItem<IContentItemElements>) => _getComponent<HorizontalRuleModel>(item, HorizontalRule)],
    [contentTypes._image_block.codename, (item: IContentItem<IContentItemElements>) => _getComponent<ImageBlockModel>(item, ImageBlock)],
    [contentTypes._card_list.codename, (item: IContentItem<IContentItemElements>) => _getComponent<CardListModel>(item, CardList)],
    [contentTypes._call_to_action.codename, (item: IContentItem<IContentItemElements>) => _getComponent<CallToActionModel>(item, CallToAction)],
    [contentTypes.image_block_split_content.codename, (item: IContentItem<IContentItemElements>) => _getComponent<ImageBlockSplitContentModel>(item, SplitContentImageBlock)],
    [contentTypes.basic_content_block_split_content.codename, (item: IContentItem<IContentItemElements>) => _getComponent<BasicContentBlockModel>(item, SplitContentBasicContentBlock)],
    [contentTypes._split_content_block.codename, (item: IContentItem<IContentItemElements>) => _getComponent<SplitContentBlockModel>(item, SplitContentBlock)],
    [contentTypes._basic_content_block.codename, (item: IContentItem<IContentItemElements>) => _getComponent<BasicContentBlockModel>(item, BasicContentBlock)],
    [contentTypes._accordion_list.codename, (item: IContentItem<IContentItemElements>) => _getComponent<AccordionListModel>(item, AccordionList)],
    [contentTypes._accordion_item.codename, (item: IContentItem<IContentItemElements>) => _getComponent<AccordionItemModel>(item, AccordionItem)],
    [contentTypes.anchor_tag.codename, (item: IContentItem<IContentItemElements>) => _getComponent<AnchorTagModel>(item, AnchorTag)],
    [contentTypes._cohort_list.codename, (item: IContentItem<IContentItemElements>) => _getComponent<CohortListModel>(item, CohortList)]
]);

function _getComponent<Model>(item: IContentItem<IContentItemElements>, component: any) {

    model.value = item as Model;
    if (optionsTypeMap.has(component)) {
        options.value = optionsTypeMap.get(component);
    }

    return component;
}
//#endregion

const props = defineProps<
    PortableTextComponentProps<{
        component: any
    }>
>();


let activeComponent: any = null
const model = ref<any>();
const options = ref<any>();
const templateClass = ref<string>();


let linkedItem: any = null
const pageStore = usePageStore()
const linkedItems = pageStore.linkedItems as IContentItemsContainer
if (props.value.component._type === 'reference') {
    const codename = props.value.component._ref

    linkedItem = linkedItems[codename]
    if (linkedItem) {
        activeComponent = getComponent(linkedItem)
    }
}

function getComponent(item: IContentItem<IContentItemElements>) {

    try {
        if (!item?.system?.type) {
            console.log("type not provided");
            return null;
        }
        else {
            if (contentTypeMap.has(item.system.type)) {
                const comp = contentTypeMap.get(item.system.type);
                if (comp) {
                    return comp(item);
                }
            }
            else {
                console.error(`Missing Map: ${item.system.type}`);
            }
        }
    }
    catch (error) {
        console.log('error', error)
    }


    return null
}




</script>
