<template>
    <section class="split-image-block" v-bind:[attributes.componentId]="type.id">
        <div v-if="hasLeftAndRightContent" class="left-and-right-content">
            <div class="container-fluid" :class="paddingOptions(selectedPaddingOptions)">
                <div class="row">
                    <div class="col-sm-6 left-content">
                        <div class="inner-container">
                            <h4 class="title" v-bind:[attributes.elementCodename]="type.elements.left_title.codename">
                                {{ leftTitle }}
                            </h4>
                            <div class="primary-logos"
                                v-bind:[attributes.elementCodename]="type.elements.left_primary_logos.codename">
                                <template v-for="logo in leftPrimaryLogos">
                                    <NuxtLink :to="logo.elements.link.value">
                                        <NuxtImg v-if="logo.elements.imageGreyscale.value.length > 0"
                                            :src="logo.elements.imageGreyscale.value[0].url" :alt="logo.elements.title.value" />
                                    </NuxtLink>
                                </template>
                            </div>
                            <div class="secondary-logos"
                                v-bind:[attributes.elementCodename]="type.elements.left_secondary_logos.codename">
                                <template v-for="logo in leftSecondaryLogos">
                                    <NuxtLink :to="logo.elements.link.value">
                                        <NuxtImg v-if="logo.elements.imageGreyscale.value.length > 0"
                                            :src="logo.elements.imageGreyscale.value[0].url" :alt="logo.elements.title.value" />
                                    </NuxtLink>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 right-content">
                        <div class="inner-container">
                            <h4 class="title" v-bind:[attributes.elementCodename]="type.elements.right_title.codename">
                                {{ rightTitle }}
                            </h4>
                            <div class="primary-logos"
                                v-bind:[attributes.elementCodename]="type.elements.right_primary_logos.codename">
                                <template v-for="logo in rightPrimaryLogos">
                                    <NuxtLink :to="logo.elements.link.value">
                                        <NuxtImg v-if="logo.elements.imageGreyscale.value.length > 0"
                                            :src="logo.elements.imageGreyscale.value[0].url" :alt="logo.elements.title.value" />
                                    </NuxtLink>
                                </template>
                            </div>
                            <div class="secondary-logos"
                                v-bind:[attributes.elementCodename]="type.elements.right_secondary_logos.codename">
                                <template v-for="logo in rightSecondaryLogos">
                                    <NuxtLink :to="logo.elements.link.value">
                                        <NuxtImg v-if="logo.elements.imageGreyscale.value.length > 0"
                                            :src="logo.elements.imageGreyscale.value[0].url" :alt="logo.elements.title.value" />
                                    </NuxtLink>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="left-only-content">
            <div class="container" :class="paddingOptions(selectedPaddingOptions)">
                <div class="row">
                    <div class="col">
                        <div class="inner-container">
                            <h4 class="title" v-bind:[attributes.elementCodename]="type.elements.left_title.codename">
                                {{ leftTitle }}
                            </h4>
                            <div class="primary-logos d-md-flex"
                                v-bind:[attributes.elementCodename]="type.elements.left_primary_logos.codename">
                                <template v-for="logo in leftPrimaryLogos">
                                    <NuxtLink :to="logo.elements.link.value">
                                        <NuxtImg v-if="logo.elements.imageGreyscale.value.length > 0"
                                            :src="logo.elements.imageGreyscale.value[0].url" :alt="logo.elements.title.value" />
                                    </NuxtLink>
                                </template>
                            </div>
                            <div class="secondary-logos"
                                v-bind:[attributes.elementCodename]="type.elements.left_secondary_logos.codename">
                                <template v-for="logo in leftSecondaryLogos">
                                    <NuxtLink :to="logo.elements.link.value">
                                        <NuxtImg v-if="logo.elements.imageGreyscale.value.length > 0"
                                            :src="logo.elements.imageGreyscale.value[0].url" :alt="logo.elements.title.value" />
                                    </NuxtLink>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { contentTypes, type CohortItemModel, type SplitImageBlockModel } from '~/models';
const pageStore = usePageStore();
const props = defineProps<{ model: SplitImageBlockModel }>();
const data = props.model.elements;
const leftTitle = ref('');
const rightTitle = ref('');
leftTitle.value = data.leftTitle.value;
rightTitle.value = data.rightTitle.value;
const leftPrimaryLogos = ref<CohortItemModel[]>([]);
const leftSecondaryLogos = ref<CohortItemModel[]>([]);
const rightPrimaryLogos = ref<CohortItemModel[]>([]);
const rightSecondaryLogos = ref<CohortItemModel[]>([]);

leftPrimaryLogos.value = [...data.leftPrimaryLogos.value.map(codename => pageStore.getLinkedItem<CohortItemModel>(codename))];
leftSecondaryLogos.value = [...data.leftSecondaryLogos.value.map(codename => pageStore.getLinkedItem<CohortItemModel>(codename))];
rightPrimaryLogos.value = [...data.rightPrimaryLogos.value.map(codename => pageStore.getLinkedItem<CohortItemModel>(codename))];
rightSecondaryLogos.value = [...data.rightSecondaryLogos.value.map(codename => pageStore.getLinkedItem<CohortItemModel>(codename))];

const selectedPaddingOptions = ref<string[]>([]);
selectedPaddingOptions.value = props.model.elements.paddingModificationsPaddingModifications.value.map((item) => item.codename);

const attributes = GetKontentAttributes();
const type = contentTypes._split_image_block;

const hasLeftAndRightContent = computed(() => {
    return leftPrimaryLogos.value.length > 0 && rightPrimaryLogos.value.length > 0;
});

</script>

<style lang="scss" scoped></style>