<template>
    <div class="split-content-image-block" v-bind:[attributes.componentId]="type.id" >
        <NuxtImg :src="imageUrl" :alt="imageName" class="img-fluid" v-bind:[attributes.elementCodename]="type.elements.primary_image.codename"/>
    </div>
</template>

<script setup lang="ts">
import { contentTypes, type ImageBlockSplitContentModel } from '~/models';

const props = defineProps<{model:ImageBlockSplitContentModel}>();

const imageUrl = ref();
const imageName = ref('');

imageName.value = props.model.elements.primaryImage.name;
imageUrl.value = props.model.elements.primaryImage?.value[0]?.url;


const attributes = GetKontentAttributes();
const type = contentTypes.image_block_split_content;

</script>

<style lang="scss" scoped>
</style>