<template>
    <div class="accordion-list" v-bind:[attributes.componentId]="type.id" >
        <div class="accordion" id="accordion-list">
            <div v-for="(item, index) in items" :key="index" class="accordion-item">
                <AccordionItem :model="item" :parent-id="item.system.id" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">

import { contentTypes, type AccordionItemModel, type AccordionListModel } from '~/models';

const pageStore = usePageStore();

const props = defineProps<{ model: AccordionListModel }>();

const items = ref<AccordionItemModel[]>([]);
const title = ref('');
items.value = [...props.model.elements.accordionItems.value.map(codename => pageStore.getLinkedItem<AccordionItemModel>(codename))];
title.value = props.model.elements.title.value;


const attributes = GetKontentAttributes();
const type = contentTypes._accordion_list;
</script>

<style lang="scss" scoped></style>