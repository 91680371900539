<template>
    <div class="card-item" v-bind:[attributes.componentId]="type.id">
        <div class="icon" v-bind:[attributes.elementCodename]="type.elements.icon.codename">
            <NuxtImg provider="kontentAiProvider" :src="icon" v-if="icon" />
        </div>
        <div class="text">
            <h4 class="title" v-bind:[attributes.elementCodename]="type.elements.title.codename">
                {{ model?.elements?.title?.value }}
            </h4>
            <div class="content" v-bind:[attributes.elementCodename]="type.elements.content.codename">
                <RichText :value="model?.elements?.content?.value"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { contentTypes, type CardItemModel } from '~/models';
const icon = ref('');
const title = ref('');
const content = ref('');
const props = defineProps<{model:CardItemModel}>();

const attributes = GetKontentAttributes();
const type = contentTypes._card_item;

if (props?.model?.elements){
    title.value = props.model.elements.title.value;
    content.value = props.model.elements.content.value;
}


if (props.model.elements.icon.value.length > 0)
    icon.value = props.model.elements.icon.value[0].url;


</script>