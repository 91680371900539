<template>
    <NuxtImg provider="kontentAiProvider" 
            v-if="source && source.length && source.length == 1" 
            :src="source[0].url" class="img-fluid" alt="..." />
     
    <div v-if="source && source.length && source.length > 1" :id="carouselId" class="carousel slide" data-bs-ride="false">
        <div class="carousel-indicators">
            <button v-for="(item, index) in source" type="button" :data-bs-target="`#${carouselId}`" 
                    :data-bs-slide-to="index" :class="getItemClass(carouselId, index.toString(), false)"
                    aria-current="true" :aria-label="`Slide ${index}`">
            </button>
        </div>
        <div class="carousel-inner">
            <div v-for="(item, index) in source" :class="getItemClass(carouselId, index.toString())">
                <NuxtImg provider="kontentAiProvider" :src="item.url" class="d-block w-100" alt="..."/>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" :data-bs-target="`#${carouselId}`" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" :data-bs-target="`#${carouselId}`" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>       
    </div>
</template>
<script lang="ts" setup>
import { contentTypes, type ImageBlockModel } from '~/models';

const attributes = GetKontentAttributes();
const type = contentTypes._image_block.elements;


//#region Carousel
const carouselActiveMap = new Map<string,string>();

const props = defineProps<{model:ImageBlockModel, carouselId:string}>();

const source = props.model.elements.mobileImages.value;


if (source && source.length && source.length > 0){
    carouselActiveMap.set(props.carouselId, '0');
}

function getItemClass(carouselId:string,itemId:string, includeCarousel: boolean = true){
    if (!carouselActiveMap.has(carouselId)) return '';


    if (includeCarousel){
        return carouselActiveMap.get(carouselId) === itemId ? 'carousel-item active':'carousel-item';
    }

    return carouselActiveMap.get(carouselId) === itemId ? 'active':'';
}




//#endregion

const pageStore = usePageStore();
</script>