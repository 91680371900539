<template>
    <section class="basic-content-block" v-bind:[attributes.componentId]="type.id">
        <div class="container" :class="paddingOptions(selectedPaddingOptions)">
            <div class="row">
                <div class="col-lg-9">
                    <h2 class="title" v-bind:[attributes.elementCodename]="type.elements.title.codename">
                        {{ title }}
                    </h2>
                    <div class="content" v-bind:[attributes.elementCodename]="type.elements.content.codename">
                        <RichText :value="content" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { contentTypes, type BasicContentBlockModel } from '~/models';

const props = defineProps<{ model: BasicContentBlockModel }>();
const title = ref('');
const content = ref('');
title.value = props.model.elements.title.value;
content.value = props.model.elements.content.value;
const selectedPaddingOptions = ref<string[]>([]);
selectedPaddingOptions.value = props.model.elements.paddingModificationsPaddingModifications.value.map((item) => item.codename);
const attributes = GetKontentAttributes();
const type = contentTypes._basic_content_block;
</script>

<style lang="scss" scoped></style>