<template>
    <NuxtLink class="btn btn-gradient" :to="linkedTargetUrl"
        v-bind:[attributes.componentId]="type.id" 
        v-bind:[attributes.elementCodename]="type.elements.link_title.codename"
        :target="newTab ? '_blank' : '_self'" :data="linkTitle"></NuxtLink>
</template>

<script setup lang="ts">
import { contentTypes } from '~/models';
import type { CallToActionModel } from '~/models'
const props = defineProps<{ model: CallToActionModel }>();
const siteStore = useSiteStore()
const attributes = GetKontentAttributes();
const type = contentTypes._call_to_action;
const newTab = ref(false)
const linkedTargetUrl = ref<string>('');
const linkTitle = ref<string>('');
linkedTargetUrl.value = getRef(props.model)
if (props?.model?.elements){
    linkTitle.value = props.model.elements.linkTitle.value;
}

function getRef(model: CallToActionModel): string {
    if (model?.elements?.linkedTarget?.value.length > 0) {
        const codename = model.elements.linkedTarget.value[0]
        return siteStore.getUrlByCodename(codename)
    }

    if (model?.elements?.manualTarget?.value) {
        return model.elements.manualTarget.value
    }

    if (model?.elements?.genericAssetTarget.value.length > 0) {
        const asset = model.elements.genericAssetTarget.value[0]
        newTab.value = true
        return asset.url
    }

    // This should be the default value/route or stuff like that.
    return '/';
}


function showEmailAddress(model: CallToActionModel): boolean {
    return !!model.elements.emailAddress;
}
</script>

<style lang="scss" scoped></style>