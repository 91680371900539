<template>
    <NuxtImg provider="kontentAiProvider" :src="imageUrl"></NuxtImg>
</template>
<script lang="ts" setup>
import type { PortableTextComponentProps } from '@portabletext/vue';
const props = defineProps<
    PortableTextComponentProps<{
        component: any
    }>
>();

//@ts-ignore
const imageUrl = props.value.asset.url
</script>