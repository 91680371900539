export const paddingOptions = (values: string[]) => {
  let classNames = ''
  if (values.length > 0) {
    for (const item of values) {
      if (item === 'no_top_padding') {
        classNames += ' pt-0'
      } else if (item === 'no_bottom_padding') {
        classNames += ' pb-0'
      }
    }
  }
  return classNames
}
