<template>
    <NuxtLink v-if="!href.startsWith('#')" :to="href" :target="linkTarget">
        {{ text }}
    </NuxtLink>
    <button v-else @click="scrollToElement(href, 130)" class="btn btn-link">{{ text }}</button>
</template>
<script lang="ts" setup>

const { scrollToElement } = useSmoothScroll()
const siteStore = useSiteStore()
const props = defineProps<
    {
        text: string,
        value: any
    }
>();

const href = ref<string>('')
const linkTarget = ref<string>('_self')
if (props.value.reference?._type == 'reference') {
    const itemId = props.value.reference._ref
    const path = siteStore.getUrlByItemId(itemId)
    href.value = path
}
else if ('data-asset-id' in props.value) {
    //console.log('data-asset-id', props.value['data-asset-id'])
    href.value = props.value.href
    linkTarget.value = '_blank'
}
else {
    href.value = props.value.href
    linkTarget.value = '_blank'
}


</script>