<template>
    <div class="split-content-basic-content-block" v-bind:[attributes.componentId]="type.id"  
    v-bind:[attributes.elementCodename]="type.elements.content.codename">
        <RichText :value="content" />
    </div>
</template>

<script setup lang="ts">
import { contentTypes, type BasicContentBlockSplitContentModel } from '~/models';

const props = defineProps<{ model: BasicContentBlockSplitContentModel }>();
const content = ref('');
content.value = props.model.elements.content.value;

const attributes = GetKontentAttributes();
const type = contentTypes.basic_content_block_split_content;

</script>

<style lang="scss" scoped></style>