<template>
    <PortableText v-if="portableTextJson" :value="portableTextJson" :components="components"
        :onMissingComponent="onMissingComponent" />
</template>
<script setup lang="ts">

import PtComponent from "~/components/PortableText/PtComponent.vue"
import PtImage from "~/components/PortableText/PtImage.vue"
import PtTable from "~/components/PortableText/PtTable.vue"
import PtItemLink from "~/components/PortableText/PtItemLink.vue"
import { isEmpty } from '~/utils/kontent-helper'
//@ts-ignore
import { transformToPortableText, nodeParse } from "@kontent-ai/rich-text-resolver"
import {
    PortableText,
    type PortableTextComponents,
    type PortableTextMarkComponent,
    type PortableTextTypeComponent,
    type PortableTextVueComponents,
} from "@portabletext/vue";

const config = useRuntimeConfig()
const props = defineProps<{ value: string | undefined }>();
const portableTextJson = ref<any>(null)
if (props.value && !isEmpty(props.value)) {
    portableTextJson.value = transformToPortableText(nodeParse(props.value))
}

const components = {
    types: {
        component: PtComponent,
        image: PtImage,
        table: PtTable
    } as PortableTextTypeComponent,
    marks: {
        internalLink: PtItemLink,
        link: PtItemLink
    } as PortableTextMarkComponent

} as PortableTextVueComponents;

const onMissingComponent = (message: string, options: any) => {
    if (config.public.isProduction) return;
    console.log(message, {
        // eg `someUnknownType`
        type: options.type,
        // 'block' | 'mark' | 'blockStyle' | 'listStyle' | 'listItemStyle'
        nodeType: options.nodeType,
    });
};

</script>