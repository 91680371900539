<template>
    <div :id="`accordion-header-${id}-${parentId}`" class="accordion-header" v-bind:[attributes.componentId]="type.id">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            :data-bs-target="`#accordion-panel-${id}-${parentId}`" aria-expanded="false"
            :aria-controls="`accordion-panel-${id}-${parentId}`"
            v-bind:[attributes.elementCodename]="type.elements.title.codename">
            {{ title }}
        </button>
    </div>
    <div :id="`accordion-panel-${id}-${parentId}`" class="accordion-collapse collapse" v-bind:[attributes.componentId]="type.id"
        :aria-labelledby="`accordion-panel-${id}-${parentId}`" :data-bs-parent="`#${parentId}`">
        <div class="accordion-body" v-bind:[attributes.elementCodename]="type.elements.content.codename">
            <RichText :value="content" />
        </div>
    </div>
</template>

<script setup lang="ts">

import { contentTypes, type AccordionItemModel } from '~/models';

const props = defineProps<{ model: AccordionItemModel, parentId: string }>();

const content = ref('');
const title = ref('');
const id = ref('');
id.value = props.model.system.id;
content.value = props.model.elements.content.value;
title.value = props.model.elements.title.value;

const attributes = GetKontentAttributes();
const type = contentTypes._accordion_item;
</script>

<style lang="scss" scoped></style>