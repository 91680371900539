<template>
    <section class="split-content-block" v-bind:[attributes.componentId]="type.id">
        <div :class="containerClass">
            <div class="container" :class="paddingOptions(selectedPaddingOptions)">
                <div class="row">
                    <div class="left-col" :class="leftSize"
                        v-bind:[attributes.elementCodename]="type.elements.left_content.codename">
                        <RichText :value="leftItemList" />
                    </div>
                    <div class="right-col" :class="rightSize"
                        v-bind:[attributes.elementCodename]="type.elements.right_content.codename">
                        <RichText :value="rightItemList" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">

import { contentTypes, type SplitContentBlockModel } from '~/models';

const props = defineProps<{ model: SplitContentBlockModel }>();

const rightItemList = ref();
const leftItemList = ref();
const blockStyling = ref();
const rightContentOnTop = ref(false)

rightItemList.value = props.model.elements.rightContent.value;
leftItemList.value = props.model.elements.leftContent.value;
blockStyling.value = props.model.elements.blockStyling.value;

const selectedPaddingOptions = ref<string[]>([]);
selectedPaddingOptions.value = props.model.elements.paddingModificationsPaddingModifications.value.map((item) => item.codename);

rightContentOnTop.value = props.model.elements.mobileStyling.value.length > 0 && props.model.elements.mobileStyling.value[0].codename === 'right_content_on_top';

const attributes = GetKontentAttributes();
const type = contentTypes._split_content_block;

type BlockStyling = 'style-60-40' | 'style-40-60' | 'style-50-50' | 'style-70-30' | 'style-30-70' | 'None';

const getBlockStyling = (): BlockStyling => {
    if (blockStyling?.value?.length > 0) {
        const selectedStyle = props.model.elements.blockStyling.value[0].codename;

        switch (selectedStyle) {
            case 'n60_40':
                return 'style-60-40';

            case 'n40_60':
                return 'style-40-60';

            case 'n50_50':
                return 'style-50-50';

            case 'n70_30':
                return 'style-70-30';

            case 'n30_70':
                return 'style-30-70';

            default:
                return 'None';
        }
    }

    return 'None';
}

const containerClass = computed(() => {
    return getBlockStyling();
});

const leftSize = computed(() => {
    const style = getBlockStyling();
    let classNames = '';

    switch (style) {
        case 'style-60-40':
            classNames += 'col-lg-7';
            break;
        case 'style-40-60':
            classNames += 'col-lg-5';
            break;
        case 'style-50-50':
            classNames += 'col-lg-6';
            break;
        case 'style-70-30':
            classNames += 'col-lg-8';
            break;
        case 'style-30-70':
            classNames += 'col-lg-4';
            break;
        default:
            return '';
    }
    if (rightContentOnTop.value) {
        classNames += ' order-lg-1 order-2';
    }
    return classNames;

});

const rightSize = computed(() => {
    const style = getBlockStyling();
    let classNames = '';

    switch (style) {
        case 'style-60-40':
            classNames += 'col-lg-5';
            break;
        case 'style-40-60':
            classNames += 'col-lg-7';
            break;
        case 'style-50-50':
            classNames += 'col-lg-6';
            break;
        case 'style-70-30':
            classNames += 'col-lg-4';
            break;
        case 'style-30-70':
            classNames += 'col-lg-8';
            break;
        default:
            return '';
    }

    if (rightContentOnTop.value) {
        classNames += ' order-lg-2 order-1';
    }
    return classNames;
});

</script>

<style lang="scss" scoped></style>