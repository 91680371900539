<template>
    <span :id="id" class="anchor-tag__icon">
        <NuxtLink v-if="showIcon" class="anchor-link docon docon-link" :to="`#${id}`"></NuxtLink>
    </span>
</template>
<script setup lang="ts">
import type { AnchorTagModel } from '~/models';
const props = defineProps<{ model: AnchorTagModel | null }>();
const idValue = ref<string>('');
if (props.model?.elements.id.value)
    idValue.value = props.model?.elements.id.value;

const id = replaceId(idValue.value);
const showIcon = props.model?.elements.showTagIcon.value[0].codename === 'yes';
function replaceId(id: string) {
    if (/^\d/.test(id)) {
        // If it does, escape the digit with '\\3' and a trailing space for CSS selector compliance
        return '_' + id
    }
    return id
}
</script>